import { LogLevels } from "./config";
import { getUserInfo } from "../utils";

// rawMessage: string
// context?: k-v object
const log = (rawMessage, context, status, traceId) => {
  const userInfo = getUserInfo();
  const defaultContext = {
    ...userInfo,
    traceId: traceId,
    env: process.env.NEXT_PUBLIC_ENV,
    app: process.env.NEXT_PUBLIC_APP,
  };
  context = { ...defaultContext, ...context };
  if (process.env.NEXT_PUBLIC_ENV === "development") {
    console.log(rawMessage, context, status);
  }
};

class Logger {
  static levels = LogLevels;
  static initialized;
  static log = (rawMessage = "", context = {}, status = LogLevels.INFO, traceId) => {
    if (!Logger.initialized) {
      // initialize logging tool if needed
      Logger.initialized = true;
    }
    log(rawMessage, context, status, traceId);
  };
}

export { Logger };
