/* This file is used via soft links in several aws lambdas.
 * Keep this file and its dependencies in common JS style.
 * When introducing new dependencies to this file, soft-link them to aws lambdas as well.
 */

export const FINDING_STATUS = {
  PENDING: "pending",
  DECLINED: "declined",
  ACKNOWLEDGED: "acknowledged",
  PARTIALLY_RESOLVED: "partially-resolved",

  // three centralization severity statuses equal to partially resolved
  MULTI_SIG: "multi-sig",
  TIMELOCK: "timelock",
  MULTI_SIG_AND_TIMELOCK: "multi-sig-and-timelock",

  MITIGATED: "mitigated",
  RESOLVED: "resolved",

  // three centralization severity statuses equal to resolved
  RENOUNCED: "renounced",
  REMOVED: "removed",
  DISABLED: "disabled",
};

export const FINDING_STATUS_MAP = {
  [FINDING_STATUS.PENDING]: {
    label: "Pending",
  },
  [FINDING_STATUS.PARTIALLY_RESOLVED]: {
    label: "Partially Resolved",
  },
  [FINDING_STATUS.MITIGATED]: {
    label: "Mitigated",
  },
  [FINDING_STATUS.RESOLVED]: {
    label: "Resolved",
  },
  [FINDING_STATUS.ACKNOWLEDGED]: {
    label: "Acknowledged",
  },
  [FINDING_STATUS.DECLINED]: {
    label: "Declined",
  },

  // centralization severity statuses
  [FINDING_STATUS.MULTI_SIG]: {
    label: "Multi-Sig",
  },
  [FINDING_STATUS.TIMELOCK]: {
    label: "Timelock",
  },
  [FINDING_STATUS.MULTI_SIG_AND_TIMELOCK]: {
    label: "Multi-Sig & Timelock",
  },
  [FINDING_STATUS.RENOUNCED]: {
    label: "Renounced",
  },
  [FINDING_STATUS.REMOVED]: {
    label: "Removed",
  },
  [FINDING_STATUS.DISABLED]: {
    label: "Disabled",
  },
};

export const FINDING_PROGRESS_STATUS = {
  ACKNOWLEDGED: "acknowledged",
  PENDING: "pending",
  ACTION_IN_PROGRESS: "action-in-progress",
  RESOLVED: "resolved",
};

export const FINDING_PROGRESS_STATUS_MAP = {
  [FINDING_PROGRESS_STATUS.PENDING]: {
    label: "Pending",
    tagType: "Med-Yellow",
  },
  [FINDING_PROGRESS_STATUS.ACKNOWLEDGED]: {
    label: "Acknowledged",
    tagType: "Med-LightBlue",
  },
  [FINDING_PROGRESS_STATUS.ACTION_IN_PROGRESS]: {
    label: "Action in progress",
    tagType: "Med-Green",
  },
  [FINDING_PROGRESS_STATUS.RESOLVED]: {
    label: "Resolved",
    tagType: "Med-Cyan",
  },
};
