import { COLORS } from "./colors";
/**
 * Theoretically, any report should have the "projectType" attribute in the "summary" object.
 * The DEFAULT_FINDING_REPORT_TYPE is meant for the fallback value if that attribute is not defined.
 * This value is also used as fallback value in FINDING_DYNAMIC_SEVERITY map, if the incoming report has undefined or invalid "projectType".
 * */
export const DEFAULT_FINDING_REPORT_TYPE = "audit";

export const FINDING_REPORTS_TYPE = {
  INCREMENTAL: "incremental", // Tracked in reports db
  AUDIT: DEFAULT_FINDING_REPORT_TYPE, // Empty field in reports db
  MARKETPLACE: "marketplace", // Not a db-level concept. Basically audit report type but read-only.
};

export const FINDING_REPORTS_MAP = {
  [FINDING_REPORTS_TYPE.INCREMENTAL]: {
    title: "Stepwise Findings",
    shareLinkRouting: "incremental/",
  },
};

export const FINDING_STATUS_ENUM = [
  "pending",
  "partially-resolved",
  "mitigated",
  "resolved",
  "acknowledged",
  "declined",
];

export const FINDING_FEEDBACK_TYPE_ENUM = {
  THUMBS_UP: "thumbs-up",
  THUMBS_DOWN: "thumbs-down",
};

export const FINDINGS_DETAIL_ERROR = {
  NO_FINDINGS: "no-findings",
  NO_REPORTS: "no-reports",
  SELECTED_FINDING_NOT_FOUND: "selected-finding-not-found",
};

export const FINDINGS_DETAIL_ERROR_MESSAGE = {
  [FINDINGS_DETAIL_ERROR.NO_FINDINGS]: "There are no findings associated with this report",
  [FINDINGS_DETAIL_ERROR.NO_REPORTS]: "There are no reports associated with this project",
  [FINDINGS_DETAIL_ERROR.SELECTED_FINDING_NOT_FOUND]: "The selected finding could not be found",
};

export const DEFAULT_FINDING = {
  severity: "info",
  recommendation: "",
  description: "",
  locations: [],
  id: "",
  category: [],
  title: "Anonymous Finding",
  status: "pending",
};

export const FINDING_LIST_SORT_TYPE = {
  NONE: "none",
  ID: "id",
  SEVERITY: "severity",
  LAST_COMMENT_TIME: "lastCommentTime",
  FINDING_UPLOAD_TIME: "uploadTime",
};

export const FINDING_LIST_SORT_LABEL = {
  [FINDING_LIST_SORT_TYPE.NONE]: "All Types",
  [FINDING_LIST_SORT_TYPE.ID]: "ID",
  [FINDING_LIST_SORT_TYPE.SEVERITY]: "Severity",
  [FINDING_LIST_SORT_TYPE.LAST_COMMENT_TIME]: "Last Comment Time",
  [FINDING_LIST_SORT_TYPE.FINDING_UPLOAD_TIME]: "Updated Time",
};

export const FINDING_LIST_FILTER_TYPE = {
  SEVERITY_CRITICAL: "severity:critical",
  SEVERITY_CENTRALIZATION: "severity:centralization",
  SEVERITY_MAJOR: "severity:major",
  SEVERITY_MEDIUM: "severity:medium",
  SEVERITY_MINOR: "severity:minor",
  SEVERITY_OPTIMIZATION: "severity:optimization",
  SEVERITY_INFORMATIONAL: "severity:info",
  SEVERITY_DISCUSSION: "severity:discussion",
};

export const FINDING_LIST_FILTER_TYPE_FOR_PENTEST = {
  SEVERITY_CRITICAL: "severity:critical",
  SEVERITY_HIGH: "severity:high",
  SEVERITY_MEDIUM: "severity:medium",
  SEVERITY_LOW: "severity:low",
  SEVERITY_INFORMATIONAL: "severity:info",
};

export const FINDING_LIST_FILTER_FUNCTION = {
  [FINDING_LIST_FILTER_TYPE.SEVERITY_CRITICAL]: (finding) =>
    finding.severity === FINDING_SEVERITY.CRITICAL,
  [FINDING_LIST_FILTER_TYPE.SEVERITY_CENTRALIZATION]: (finding) =>
    finding.severity === FINDING_SEVERITY.CENTRALIZATION,
  [FINDING_LIST_FILTER_TYPE.SEVERITY_MAJOR]: (finding) =>
    finding.severity === FINDING_SEVERITY.MAJOR,
  // adhoc way for pentest report
  [FINDING_LIST_FILTER_TYPE_FOR_PENTEST.SEVERITY_HIGH]: (finding) =>
    finding.severity === FINDING_SEVERITY_FOR_PENTEST.HIGH,
  // end
  [FINDING_LIST_FILTER_TYPE.SEVERITY_MEDIUM]: (finding) =>
    finding.severity === FINDING_SEVERITY.MEDIUM,
  [FINDING_LIST_FILTER_TYPE.SEVERITY_MINOR]: (finding) =>
    finding.severity === FINDING_SEVERITY.MINOR,
  // adhoc way for pentest report
  [FINDING_LIST_FILTER_TYPE_FOR_PENTEST.SEVERITY_LOW]: (finding) =>
    finding.severity === FINDING_SEVERITY_FOR_PENTEST.LOW,
  // end
  [FINDING_LIST_FILTER_TYPE.SEVERITY_OPTIMIZATION]: (finding) =>
    finding.severity === FINDING_SEVERITY.OPTIMIZATION,
  [FINDING_LIST_FILTER_TYPE.SEVERITY_INFORMATIONAL]: (finding) =>
    finding.severity === FINDING_SEVERITY.INFORMATIONAL,
  [FINDING_LIST_FILTER_TYPE.SEVERITY_DISCUSSION]: (finding) =>
    finding.severity === FINDING_SEVERITY.DISCUSSION,
};

export const FINDING_LIST_FILTER_LABEL = {
  [FINDING_LIST_FILTER_TYPE.SEVERITY_CRITICAL]: "Severity: Critical",
  [FINDING_LIST_FILTER_TYPE.SEVERITY_CENTRALIZATION]: "Severity: Centralization",
  [FINDING_LIST_FILTER_TYPE.SEVERITY_MAJOR]: "Severity: Major",
  [FINDING_LIST_FILTER_TYPE.SEVERITY_MEDIUM]: "Severity: Medium",
  [FINDING_LIST_FILTER_TYPE.SEVERITY_MINOR]: "Severity: Minor",
  [FINDING_LIST_FILTER_TYPE.SEVERITY_OPTIMIZATION]: "Severity: Optimization",
  [FINDING_LIST_FILTER_TYPE.SEVERITY_INFORMATIONAL]: "Severity: Informational",
  [FINDING_LIST_FILTER_TYPE.SEVERITY_DISCUSSION]: "Severity: Discussion",
};

export const FINDING_LIST_FILTER_LABEL_FOR_PENTEST = {
  [FINDING_LIST_FILTER_TYPE_FOR_PENTEST.SEVERITY_CRITICAL]: "Severity: Critical",
  [FINDING_LIST_FILTER_TYPE_FOR_PENTEST.SEVERITY_HIGH]: "Severity: High",
  [FINDING_LIST_FILTER_TYPE_FOR_PENTEST.SEVERITY_MEDIUM]: "Severity: Medium",
  [FINDING_LIST_FILTER_TYPE_FOR_PENTEST.SEVERITY_LOW]: "Severity: Low",
  [FINDING_LIST_FILTER_TYPE_FOR_PENTEST.SEVERITY_INFORMATIONAL]: "Severity: Informational",
};

export const FINDING_SEVERITY_FOR_PENTEST = {
  CRITICAL: "critical",
  HIGH: "high",
  MEDIUM: "medium",
  LOW: "low",
  INFORMATIONAL: "info",
};
export const FINDING_SEVERITY = {
  CRITICAL: "critical",
  CENTRALIZATION: "centralization",
  MAJOR: "major",
  MEDIUM: "medium",
  MINOR: "minor",
  OPTIMIZATION: "optimization",
  INFORMATIONAL: "info",
  DISCUSSION: "discussion",
};
export const FINDING_SEVERITY_ALIAS_MAP = {
  // for compatibility
  Critical: "critical",
  Centralization: "centralization",
  Major: "major",
  Medium: "medium",
  Minor: "minor",
  Optimization: "optimization",
  Informational: "info",
  Discussion: "discussion",
};
export const FINDING_SEVERITY_ALIAS_MAP_FOR_PENTEST = {
  // for compatibility
  Critical: "critical",
  High: "high",
  Medium: "medium",
  Low: "low",
  Informational: "info",
};
export const FINDING_SEVERITY_MAP = {
  [FINDING_SEVERITY.CRITICAL]: {
    label: "Critical",
    level: 1,
    color: COLORS["CK2"],
    tagType: "Med-Red",
  },
  [FINDING_SEVERITY.CENTRALIZATION]: {
    label: "Centralization",
    level: 2,
    color: COLORS["CK2"],
    tagType: "Med-Red",
  },
  [FINDING_SEVERITY.MAJOR]: {
    label: "Major",
    level: 3,
    color: COLORS["CK2"],
    tagType: "Med-Red",
  },
  [FINDING_SEVERITY.MEDIUM]: {
    label: "Medium",
    level: 4,
    color: COLORS["CK2-yellow"],
    tagType: "Med-Yellow",
  },
  [FINDING_SEVERITY.MINOR]: {
    label: "Minor",
    level: 5,
    color: COLORS["CK2-yellow"],
    tagType: "Med-Yellow",
  },
  [FINDING_SEVERITY.OPTIMIZATION]: {
    label: "Optimization",
    level: 6,
    color: COLORS["CK2-cyan"],
    tagType: "Med-Cyan",
  },
  [FINDING_SEVERITY.INFORMATIONAL]: {
    label: "Informational",
    level: 7,
    color: COLORS["gray-600"],
    tagType: "Med-LightBlue",
  },
  [FINDING_SEVERITY.DISCUSSION]: {
    label: "Discussion",
    level: 8,
    color: COLORS["CK2-green"],
    tagType: "Med-Green",
  },
};
export const FINDING_SEVERITY_MAP_FOR_PENTEST = {
  [FINDING_SEVERITY_FOR_PENTEST.CRITICAL]: {
    label: "Critical",
    level: 1,
    color: COLORS["CK2"],
    tagType: "Med-Red",
  },
  [FINDING_SEVERITY_FOR_PENTEST.HIGH]: {
    label: "High",
    level: 2,
    color: COLORS["CK2"],
    tagType: "Med-Red",
  },
  [FINDING_SEVERITY_FOR_PENTEST.MEDIUM]: {
    label: "Medium",
    level: 3,
    color: COLORS["CK2-yellow"],
    tagType: "Med-Yellow",
  },
  [FINDING_SEVERITY_FOR_PENTEST.LOW]: {
    label: "Low",
    level: 4,
    color: COLORS["CK2-yellow"],
    tagType: "Med-Yellow",
  },
  [FINDING_SEVERITY_FOR_PENTEST.INFORMATIONAL]: {
    label: "Informational",
    level: 5,
    color: COLORS["gray-600"],
    tagType: "Med-LightBlue",
  },
};
export const FINDING_DYNAMIC_SEVERITY = {
  pentest: {
    SEVERITY: FINDING_SEVERITY_FOR_PENTEST,
    SEVERITY_MAP: FINDING_SEVERITY_MAP_FOR_PENTEST,
    ALIAS_MAP: FINDING_SEVERITY_ALIAS_MAP_FOR_PENTEST,
    FILTER_TYPE: FINDING_LIST_FILTER_TYPE_FOR_PENTEST,
    FILTER_LABEL: FINDING_LIST_FILTER_LABEL_FOR_PENTEST,
  },
  // DEFAULT
  audit: {
    SEVERITY: FINDING_SEVERITY,
    SEVERITY_MAP: FINDING_SEVERITY_MAP,
    ALIAS_MAP: FINDING_SEVERITY_ALIAS_MAP,
    FILTER_TYPE: FINDING_LIST_FILTER_TYPE,
    FILTER_LABEL: FINDING_LIST_FILTER_LABEL,
  },
};

export const FINDING_COMMENT_STATUS = {
  NONE: "none",
  CHECKED: "checked",
  UNCHECKED_WITHIN_24_HOURS: "unchecked_within_24_hours",
  UNCHECKED_WITHIN_48_HOURS: "unchecked_within_48_hours",
  UNCHECKED_BEYOND_48_HOURS: "unchecked_beyond_48_hours",
};

export const FINDING_COMMENT_STATUS_INDICATORS = {
  [FINDING_COMMENT_STATUS.UNCHECKED_WITHIN_24_HOURS]: {
    color: COLORS["gray-9"],
    description: "Unchecked comments within 24 hours",
  },
  [FINDING_COMMENT_STATUS.UNCHECKED_WITHIN_48_HOURS]: {
    color: COLORS["orange-7"],
    description: "Unchecked comments within 48 hours",
  },
  [FINDING_COMMENT_STATUS.UNCHECKED_BEYOND_48_HOURS]: {
    color: COLORS["certikRed"],
    description: "Unchecked comments beyond 48 hours",
  },
  [FINDING_COMMENT_STATUS.CHECKED]: {
    color: COLORS["gray-12"],
    description: "Checked comments",
  },
};

export const COMMENT_REQUIRED_SEVERITY = FINDING_SEVERITY.DISCUSSION;

// TODO: move suggestions to remote config
export const FINDING_COMMENT_SUGGESTIONS = [
  { id: 1, text: "Issue acknowledged. I won't make any changes for the current version." },
  {
    id: 2,
    text: "Issue acknowledged. Changes have been reflected in the commit hash:",
  },
  {
    id: 3,
    text: "Issue acknowledged. I will fix the issue in the future, which will not be included in this audit engagement.",
  },
];
